<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.projectName}}</label>
          </div>
          <div class="condition">
            <label>{{detailData.companyName}} </label>
            <label>所在地址：{{detailData.companyAddress}}</label>
          </div>
        </div>
      </div>
      <div class="desc">
        <div class="desc-item">
          <label class="info-text">企业性质</label><label class="info-text">{{enterpriseNatureDic[detailData.enterpriseNature]}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">所属领域</label><label class="info-text">{{fieldDic[detailData.field]}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">融资类型</label><label class="info-text">{{financeTypeDic[detailData.financeType]}}</label>
        </div>
        <div class="desc-item">
          <label class="info-text">融资金额</label><label class="info-text">{{detailData.financeAmount}}万</label>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">拟融资项目简介</div>
          <div class="content-text">{{detailData.projectIntroduction}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">主导产品简介</div>
          <div class="content-text">{{detailData.mainProductIntroduction}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">市场需求竞争分析</div>
          <div class="content-text">{{detailData.marketDemandAnalysis}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">盈利模式介绍</div>
          <div class="content-text">{{detailData.profitModel}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">股权结构及管理团队</div>
          <div class="content-text">{{detailData.ownershipStructure}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'projectDetail',
  components: {
    TopFilter
  },
  data() {
    return {
      fieldDic:{1:"生物医药",2:"先进制造",3:"现代服务业",4:"现代农业"},
      enterpriseNatureDic:{1:"国内银行贷款"},
      financeTypeDic:{1:"股权融资",2:"债权融资",3:"信贷融资",4:"其他"},
      id:null,
      detailData:{}
    }
  },
  computed:{
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.technologyFinance.projectDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img{
    height:67px;
    width:117px;
  }
  .top-info{
    height: 100%;
    flex: 1;
    margin-left: 20px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name{
      display: flex;
      align-items: center;
      flex-direction: row;
      .type{
        font-weight: 700;
      }
    }
    .condition{
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item{
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text{
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider{
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content{
  margin: 20px 80px;
  .content-item{
    margin: 40px 0;
    .content-title{
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text{
      margin-top: 10px;
      font-size: 14px;
      line-height: 2.5
    }
  }

}

</style>
